import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'DistrictId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'DistrictNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameLokalSk',
		i18n: 'localsk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameLokalCz',
		i18n: 'localcz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameLokalPl',
		i18n: 'localpl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameLokalEn',
		i18n: 'locallen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameLokalDe',
		i18n: 'localde',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictNameLokalHu',
		i18n: 'localhu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictUrlName',
		i18n: 'url',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictGoogleMapsZoom',
		i18n: 'zoomonmap3264',
		hidden: true,
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CityCount',
		i18n: 'numberofcities',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'TerritoryId',
		i18n: 'county',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
];
