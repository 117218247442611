<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="districts3267"
		tooltipMsg="manualfordistri"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'DistrictId', ascending: 0 }"
		modelId="DistrictId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #DistrictNameSk="list">
			<a :href="getUrl(list.row, 'sk')" target="_blank">{{ list.row.DistrictNameSk }}</a>
		</template>

		<template #DistrictNameCz="list">
			<a :href="getUrl(list.row, 'cz')" target="_blank">{{ list.row.DistrictNameCz }}</a>
		</template>

		<template #DistrictNamePl="list">
			<a :href="getUrl(list.row, 'pl')" target="_blank">{{ list.row.DistrictNamePl }}</a>
		</template>

		<template #DistrictNameDe="list">
			<a :href="getUrl(list.row, 'de')" target="_blank">{{ list.row.DistrictNameDe }}</a>
		</template>

		<template #DistrictNameEn="list">
			<a :href="getUrl(list.row, 'en')" target="_blank">{{ list.row.DistrictNameEn }}</a>
		</template>

		<template #DistrictNameHu="list">
			<a :href="getUrl(list.row, 'hu')" target="_blank">{{ list.row.DistrictNameHu }}</a>
		</template>

		<template #CountryId="list">
			<country-flag :countryId="list.row.CountryId"></country-flag>
		</template>

		<template #TerritoryId="list">{{ list.row.TerritoryName }}</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions, listToGroupedDropdownOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import siteUrlBuilder from '@/services/helpers/site-url-builder';
import serviceDictionary from '@/services/service/dictionary.service';

import { tableColumns } from './district.table-data';

export default {
	name: 'DistrictTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'DistrictListItem',
		};
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(DistrictId = 0) {
			this.$router.push({
				name: links.districtDetailName,
				params: { DistrictId },
			});
		},

		async prepareColumns(columns) {
			const countryGroupLabels = this.$store.getters.countryDictLabels;
			const territoryEnum = await serviceDictionary.getTerritoryEnum();

			let cols = prepareTerritoryId(columns);
			cols = prepareCountryId.bind(this)(cols);

			return cols;

			function prepareTerritoryId(columns) {
				const values = listToGroupedDropdownOptions(territoryEnum.territorySelectorItems, countryGroupLabels, {
					groupBy: 'CountryId',
				});

				return setDropdownOptions(columns, 'TerritoryId', values);
			}

			function prepareCountryId(columns) {
				const values = this.$store.getters.countryForDropdown;

				return setDropdownOptions(columns, 'CountryId', values);
			}
		},

		getUrl(row, lang) {
			return siteUrlBuilder.buildUrl(row.DomainUrl, lang) + '/' + row.DistrictUrlName;
		},
	},
};
</script>